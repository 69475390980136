<template>
  <v-row justify="start" align="center" dense>
    <v-col cols="12">
      <h3>Usuários Ativos</h3>
    </v-col>

    <v-col cols="12" class="mt-1">
      <div class="xs-12">
        <label class="overline">
          <v-icon small left color="primary">school</v-icon>
          Estudantes: {{ students }}
        </label>
      </div>
      <div class="xs-12">
        <label class="overline">
          <v-icon small left color="warning">person</v-icon>
          Docentes: {{ faculty }}
        </label>
      </div>

      <div class="xs-12">
        <label class="overline">
          <v-icon small left color="success">person</v-icon>
          Funcionários: {{ staff }}
        </label>
      </div>
    </v-col>

    <v-col cols="3" class="mt-3">
      <v-text-field
        outlined
        dense
        label="Buscar usuário"
        single-line
        v-model="search"
        append-icon="search"
      ></v-text-field>
    </v-col>

    <v-col cols="12">
      <v-data-table
        item-key="id"
        :search="search"
        hide-default-footer
        v-bind:items="users"
        v-bind:headers="headers"
        :items-per-page="users.length"
        loading-text="Buscando usuários"
        no-data-text="Não foram encontrados usuários"
      >
        <template v-slot:item="props">
          <tr>
            <td class="text-xs-left">{{ props.item.academicCode }}</td>
            <td class="text-xs-left">{{ props.item.name }}</td>
            <td class="text-xs-left">
              <v-chip :color="props.item.color" outlined small>{{
                props.item.role
              }}</v-chip>
            </td>
            <td class="text-xs-left">{{ dmy(props.item.activeAt) }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";
import * as storage from "@/pages/auth/storage";
import { formatDate } from "@/utils/formatDate";

export default {
  name: "ActiveUsers",
  data() {
    return {
      user: {},
      users: [],
      students: 0,
      faculty: 0,
      staff: 0,
      username: "",
      search: "",
      headers: [
        {
          sortable: true,
          text: "Matrícula",
          value: "academicCode",
          align: "left",
        },
        {
          sortable: true,
          text: "Nome",
          value: "name",
          align: "left",
        },
        {
          sortable: true,
          text: "Tipo",
          value: "role",
          align: "left",
        },
        {
          sortable: true,
          text: "Ativo em",
          value: "activeAt",
          align: "left",
        },
      ],
    };
  },
  created() {
    this.getAsyncActiveUsers();
    this.user = storage.getLocalData().user;
  },
  watch: {
    activeUsers(newValue) {
      this.faculty = 0;
      this.staff = 0;
      this.students = 0;
      this.users = [];
      let role = "";
      let color = "";

      newValue.map((user) => {
        switch (user.role) {
          case "faculty":
            this.faculty++;
            role = "Docente";
            color = "warning";
            break;
          case "staff":
            this.staff++;
            role = "Funcionário";
            color = "success";
            break;
          case "student":
            role = "Estudante";
            color = "primary";
            this.students++;
            break;
        }

        this.users.push({
          id: user.id,
          username: user.username,
          academicCode: user.academicCode,
          name: user.name,
          role: role,
          activeAt: user.activeAt,
          color: color,
        });
      });
    },
  },

  computed: {
    ...mapState("activeUsers", ["activeUsers"]),
  },

  methods: {
    ...mapActions("activeUsers", ["ActionGetActiveUsers"]),

    async getAsyncActiveUsers() {
      try {
        await this.ActionGetActiveUsers();
      } catch (err) {
        console.log(err);
      }
    },

    dmy(data) {
      return formatDate.dmy(data);
    },
  },
};
</script>

<style scoped>
</style>
