var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "start", align: "center", dense: "" } },
    [
      _c("v-col", { attrs: { cols: "12" } }, [
        _c("h3", [_vm._v("Usuários Ativos")])
      ]),
      _c("v-col", { staticClass: "mt-1", attrs: { cols: "12" } }, [
        _c("div", { staticClass: "xs-12" }, [
          _c(
            "label",
            { staticClass: "overline" },
            [
              _c(
                "v-icon",
                { attrs: { small: "", left: "", color: "primary" } },
                [_vm._v("school")]
              ),
              _vm._v(" Estudantes: " + _vm._s(_vm.students) + " ")
            ],
            1
          )
        ]),
        _c("div", { staticClass: "xs-12" }, [
          _c(
            "label",
            { staticClass: "overline" },
            [
              _c(
                "v-icon",
                { attrs: { small: "", left: "", color: "warning" } },
                [_vm._v("person")]
              ),
              _vm._v(" Docentes: " + _vm._s(_vm.faculty) + " ")
            ],
            1
          )
        ]),
        _c("div", { staticClass: "xs-12" }, [
          _c(
            "label",
            { staticClass: "overline" },
            [
              _c(
                "v-icon",
                { attrs: { small: "", left: "", color: "success" } },
                [_vm._v("person")]
              ),
              _vm._v(" Funcionários: " + _vm._s(_vm.staff) + " ")
            ],
            1
          )
        ])
      ]),
      _c(
        "v-col",
        { staticClass: "mt-3", attrs: { cols: "3" } },
        [
          _c("v-text-field", {
            attrs: {
              outlined: "",
              dense: "",
              label: "Buscar usuário",
              "single-line": "",
              "append-icon": "search"
            },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c("v-data-table", {
            attrs: {
              "item-key": "id",
              search: _vm.search,
              "hide-default-footer": "",
              items: _vm.users,
              headers: _vm.headers,
              "items-per-page": _vm.users.length,
              "loading-text": "Buscando usuários",
              "no-data-text": "Não foram encontrados usuários"
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function(props) {
                  return [
                    _c("tr", [
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(props.item.academicCode))
                      ]),
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(props.item.name))
                      ]),
                      _c(
                        "td",
                        { staticClass: "text-xs-left" },
                        [
                          _c(
                            "v-chip",
                            {
                              attrs: {
                                color: props.item.color,
                                outlined: "",
                                small: ""
                              }
                            },
                            [_vm._v(_vm._s(props.item.role))]
                          )
                        ],
                        1
                      ),
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(_vm.dmy(props.item.activeAt)))
                      ])
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }